import React, { useState, useMemo, useEffect } from "react";
import classNames from "classnames";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { ListItem } from "../../common/ListItem";
import { Button } from "../../common/Button";
import { allThemes, createThemeObjects } from "../../../utils/project";
import { themeInterface } from "../../../interfaces/projectpageInterface";
import * as styles from "./ProjectsFilter.module.scss";
import { RCASelect } from "../../common/Select";
import { titleCase } from "../../../utils/common";
interface ProjectsFilterProps {
  location: { pathname: string };
}

const ProjectsFilter: React.FC<ProjectsFilterProps> = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ProjectsThemesPage {
      projects: allMongodbAcrossrca2324Projects {
        edges {
          node {
            themes {
              slug
              text
            }
            subthemes {
              slug
              text
            }
          }
        }
      }
    }
  `);

  const projects = data.projects;

  const [currTheme, setCurrTheme] = useState("");
  const [currSubTheme, setCurrSubTheme] = useState("");

  // now change these states when the url changes
  useEffect(() => {
    const pathArray = location.pathname.split("/").filter(Boolean);

    setCurrTheme(pathArray.length > 1 ? pathArray[1] : allThemes.slug);
    setCurrSubTheme(pathArray[2] ? pathArray[2] : "");
  }, [location.pathname]);

  // create an object with all themes/subthemes that exist in the projects
  // util imported function returns object of 2 varibles
  const { themesObject, themeCombinations } = useMemo(
    () => createThemeObjects(projects.edges),
    [projects.edges],
  );

  // build an array of allProjectFilters in themes/subthemes/combos
  // need this to return false if not on projects filter pages
  const allProjectFilters = [
    ...themesObject.themes,
    ...themesObject.subthemes,
    ...themeCombinations,
  ];

  // if state changes then filter the subthemes to reflect current theme
  const subthemes = useMemo(() => {
    if (currTheme === allThemes.slug) {
      return themesObject.subthemes;
    } else {
      return themeCombinations.filter((item) => item?.theme === currTheme);
    }
  }, [currTheme]);

  const orderedSubthemes = useMemo(() => {
    return [...subthemes].sort((a, b) => {
      if ((a.title || a.title) < (b.title || b.title)) {
        return -1;
      }
      if ((a.title || a.title) > (b.title || b.title)) {
        return 1;
      }
      return 0;
    });
  }, [subthemes]);

  const orderedThemes = useMemo(() => {
    return themesObject.themes.sort((a, b) => {
      if ((a.title || a.title) < (b.title || b.title)) {
        return -1;
      }
      if ((a.title || a.title) > (b.title || b.title)) {
        return 1;
      }
      return 0;
    });
  }, [themesObject.themes]);

  // mobile menu theme array
  const mobileThemes = [...themesObject.themes];
  mobileThemes.unshift({
    slug: "/projects",
    title: "All Themes",
    theme: allThemes.slug,
    subtheme: "*",
    type: "theme",
  });

  const mobileSubthemes = [...orderedSubthemes];
  mobileSubthemes.forEach((item: any) => {
    item.title = titleCase(item.title);
  });
  mobileSubthemes.unshift({
    slug: "",
    title: "All Subthemes",
    theme: "*",
    subtheme: "*",
    type: "theme",
  });

  // mobile menu handlers
  const handleThemeChange = (value: string) => {
    // find the theme object that matches the value
    const themeObject = mobileThemes.find((item) => item?.theme === value);
    navigate(themeObject.slug);
  };

  const handleSubthemeChange = (value: string) => {
    const subthemeObject = mobileSubthemes.find(
      (item) => item?.subtheme === value,
    );
    const themeObject = mobileThemes.find((item) => item?.theme === currTheme);

    const slug =
      subthemeObject.slug === ""
        ? currTheme === allThemes.slug
          ? "/projects"
          : themeObject.slug
        : subthemeObject.slug;
    navigate(slug);
  };

  // if we don't need this filter component return nothing
  const pathname = location.pathname.endsWith("/")
    ? location.pathname.slice(0, -1)
    : location.pathname;
  const isProjectPath =
    allProjectFilters.some((item) => item?.slug === pathname) ||
    pathname === "/projects" ||
    pathname === "/project-themes";

  if (!isProjectPath) {
    return <></>;
  }

  return (
    <div>
      <ListItem
        topBorder={false}
        leftBorder={false}
        rightBorder={false}
        size="large"
        tagName="h1"
      >
        Projects
      </ListItem>
      <div className={styles.projectsThemes}>
        <nav className={styles.mobileMenu}>
          <div className={styles.label}>Filter by Theme</div>
          <div className={styles.themes}>
            <RCASelect
              tabIndex="0"
              initialLabel="Select a theme"
              onChange={handleThemeChange}
              onKeyDown={handleThemeChange}
              value={currTheme || ""}
              items={mobileThemes.map((projectTheme: themeInterface) => ({
                value: projectTheme.theme,
                label: projectTheme.title,
              }))}
              formLabel={{
                id: "filter-project-themes",
                text: "filter projects by theme",
              }}
            />
          </div>
          <div className={styles.label}>Filter by Subtheme</div>
          <RCASelect
            tabIndex="0"
            initialLabel="Select a subtheme"
            onChange={handleSubthemeChange}
            onKeyDown={handleSubthemeChange}
            value={currSubTheme || ""}
            items={mobileSubthemes?.map((projectTheme: themeInterface) => ({
              value: projectTheme.subtheme,
              label: projectTheme.title,
            }))}
            formLabel={{
              id: "filter-projects-subthemes",
              text: "filter projects by subtheme",
            }}
          />
          <Button
            className={classNames(styles.reset)}
            onClick={() => navigate("/projects")}
          >
            Reset
          </Button>
        </nav>

        <nav className={styles.desktopMenu}>
          <ul>
            <li className={styles.listTitle}>Themes</li>
            <ul className={styles.themeList}>
              {orderedThemes.map(
                (projectTheme: themeInterface, index: number) => {
                  return (
                    <li key={index}>
                      <Button
                        to={projectTheme.slug}
                        className={classNames(styles.themeButton, {
                          [styles.active]: currTheme === projectTheme.theme,
                          [styles.caring]:
                            projectTheme?.theme === "caring-society",
                          [styles.digital]:
                            projectTheme?.theme === "being-digital",
                          [styles.climate]:
                            projectTheme?.theme === "climate-crisis",
                          [styles.justice]:
                            projectTheme?.theme ===
                            "justice-equality-and-misinformation",
                        })}
                      >
                        {projectTheme.title}
                      </Button>
                    </li>
                  );
                },
              )}
              <li>
                <Button
                  to={"/projects"}
                  className={classNames({
                    [styles.active]: currTheme === allThemes.slug,
                  })}
                >
                  All Themes
                </Button>
              </li>
            </ul>
            <li className={styles.listTitle}>Subthemes</li>
            <ul>
              {orderedSubthemes.length === 0
                ? "There are no subthemes"
                : orderedSubthemes?.map((projectTheme, index) => {
                    return (
                      <li key={index}>
                        <Button
                          to={projectTheme?.slug}
                          className={classNames({
                            [styles.active]:
                              currSubTheme === projectTheme?.subtheme,
                          })}
                        >
                          {titleCase(projectTheme?.title)}
                        </Button>
                      </li>
                    );
                  })}
            </ul>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export { ProjectsFilter };
