import React from "react";

import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "gatsby";
import CookieConsent from "react-cookie-consent";

import { useRCAContext } from "../../../context/RCAContext";
import { animation } from "../../../utils/animation";
import { ModeToggles } from "../ColourMode/ModeToggles";
import * as styles from "./Footer.module.scss";

const variants = {
  initial: {
    y: "200%",
  },
  enter: {
    y: 0,
    transition: {
      duration: animation.page.duration,
      delay: animation.page.duration,
      when: "beforeChildren",
    },
  },
  exit: {
    // y: layout.footer.height * 2,
    y: "200%",
    transition: { duration: animation.page.duration },
  },
};

const Footer = ({ location }) => {
  const { isSearchOpen, bodyLocked } = useRCAContext();

  const pathArray = location.pathname.split("/");

  // const students = pathArray[1] === 'students' && !pathArray[2];
  const studentPage = pathArray[1] === "students" && pathArray[2];

  const showMode = !studentPage;
  return (
    <>
      <AnimatePresence>
        {/* {location.pathname !== "/" && ( */}
        <motion.footer
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          className={classNames(styles.footer, {
            [styles.bodyLocked]: bodyLocked,
          })}
        >
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="gatsby-gdpr-google-analytics"
            disableStyles
            containerClasses={styles.consent}
            contentClasses={styles.consentContent}
            buttonClasses={styles.consentBtn}
          >
            We use cookies and similar methods to recognize visitors and
            remember their preferences. We also use them to measure ad campaign
            effectiveness, target ads and analyze site traffic. To learn more
            about these methods, including how to disable them, view our{" "}
            <a href="/about/#privacy-policy">Cookie Policy</a>. By clicking
            ‘Accept,’ you consent to the processing of your data by us and third
            parties using the above methods.
            {/* You can always change your tracker preferences by visiting our Cookie Policy. */}
          </CookieConsent>

          <div className={styles.footerLeft}>
            <Link to={"/"}>AcrossRCA 2024</Link>
          </div>
          <div as="nav" className={styles.footerRight}>
            <motion.div
              className={styles.toggles}
              variants={variants}
              initial="initial"
              animate={isSearchOpen ? "exit" : "enter"}
              exit="exit"
            >
              {showMode && <ModeToggles />}
            </motion.div>
          </div>
        </motion.footer>
        {/* )} */}
      </AnimatePresence>
    </>
  );
};

export { Footer };
